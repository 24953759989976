<template>
  <div class="info">
    <Breadcrumb :data="breData" class="breadcrumb" />
    <div class="content">
      <iframe title="xinx" class="iframeWrap" :frameBorder="0" :src="iframeUrl"></iframe>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import API from '@/api'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "Info",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breData: [
        {
          name: "法律查询",
          path: "/legal/query?type=CASE",
        },
        {
          name: "企业信息",
        },
      ],
      iframeUrl: '',
    }
  },
  computed: {
    ...mapGetters(["analysisData"]),
  },
  created() {
    this.getXinXToken()
    // this.getReportResult()
  },
  mounted() {},
  methods: {
    ...mapActions(["getAnalysisData"]),
    getXinXToken() {
      API.common.getXinXToken().then((res) => {
        console.log("resresres", res)
        // const keyword = '科技'
        const { searchHomeUrl, token, uerId } = res
        // const srcUrl = search ? `${baseUrl}/search?keyWord=${keyword}&searchType=ALL&dxr=true&scm-token=${token}` : `${baseUrl}/searchHomepage?dxr=true&scm-token=${token}`
        this.iframeUrl = `${searchHomeUrl}${token}&sdkSourceId=${uerId}&dxr=true&sdkSource=QDFZG&sdkPDF=true`
      })
    },
  },
}
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.info {
  margin: 0 auto;
  .breadcrumb {
    width: @uni-width;
    margin: 0 auto;
  }
  .content {
    /*margin-bottom: 20px;*/
    /*padding: 20px;*/
    /*background: #ffffff;*/
    /*box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);*/
    .iframeWrap {
      width: 100%;
      height: 100vh;
      border: none;
      margin-bottom: -5px;
    }
  }
}
</style>
